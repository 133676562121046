import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from 'react-bootstrap';

import Layout from "../components/layout";
import SEO from "../components/seo";
import SurveyForm from '../components/survey';
import styles from '../components/styles/upload.module.scss';
import ConsentModal from '../components/survey/consentModal';

const UploadPage = () => {
    const { t } = useTranslation('upload');
    const [success, setSuccess] = useState(false);

    return (
        <Layout>
            <SEO title={t('title')} />

            <main className={styles.main}>
                <Container className={styles.container}>
                    {success ? (
                        <div>
                            <p className="text-center my-5">
                                <span className="h1">{t('success_1')}</span>
                                <br /><br />
                                <span className="h2">{t('success_2')}</span>
                            </p>
                        </div>
                    ) : (
                        <>
                            <h1>{t('title')}</h1>

                            <SurveyForm formType="standard" onSuccess={() => setSuccess(true)} />
                        </>
                    )}
                </Container>
            </main>

            <ConsentModal />
        </Layout>
    );
};

export default UploadPage;
